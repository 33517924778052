import React from "react"

import "./index.css"

import NavBar from "../component/NavBar"

export default function Home() {
  return (
    <div className="Page">
      <NavBar showLogo={false} />
      <div id="Home" className="Content">
        <div className="ZenDoTextContainer">
          <p className="ZenDoText">Zen-Do</p>
          <p className="ZenDoBpText">Budapest</p>
        </div>
      </div>
    </div>
  )
}
